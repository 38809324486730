import { getMainDoorImage } from 'utilities/helpers/getMainDoorImage';

export const parseDoors = (doors) => {
    const newDoors = doors?.map((door) => ({
        type: door?.lines?.brands?.type,
        image: getMainDoorImage(door),
        headline: door?.name,
        description: door?.description,
        price: door?.price,
        id: door?.alternative_id,
        bestseller: door?.bestseller
    }));

    return newDoors;
};

export const doorTypes = {
    inner: {
        name: 'Drzwi wewnętrzne',
        shortName: 'Wewnętrzne',
        path: 'drzwi-wewnetrzne'
    },
    outer: {
        name: 'Drzwi zewnętrzne',
        shortName: 'Zewnętrzne',
        path: 'drzwi-zewnetrzne'
    },
    technical: {
        name: 'Drzwi techniczne',
        shortName: 'Techniczne',
        path: 'drzwi-techniczne'
    },
    accessories: {
        name: 'Klamki',
        shortName: 'Klamki',
        path: 'klamki'
    },
    all: {
        name: '',
        path: ''
    }
};

export const types = [doorTypes.inner.name, doorTypes.outer.name, doorTypes.technical.name];
export const shortNameTypes = [doorTypes.outer.shortName, doorTypes.inner.shortName, doorTypes.technical.shortName];

export const brands = {
    premium: 'Barański Premium',
    optimo: 'Barański Optimo',
    smart: 'Barański Smart'
};

export const sortingOptions = {
    nameAsc: 'Od A do Z',
    nameDesc: 'Od Z do A',
    priceAsc: 'Cena rosnąco',
    priceDesc: 'Cena malejąco'
};
