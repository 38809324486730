import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { paths } from 'utilities/routes';
import { doorTypes } from 'utilities/data/doors';

import { CarouselView } from './view';

export const Carousel = ({
    headline,
    buttonText,
    buttonLink,
    name,
    modifier,
    items,
    types,
    children,
    loopBreakpoint,
    ...settings
}) => {
    const [type, setType] = useState('');
    const filterItems = () => (items.filter((currentValue) => (currentValue.type === type)));

    // considers 2 special cases for door carousels (from main page, and from /premium page)
    const generateLink = () => {
        if (buttonLink === '/baranski-premium') {
            if (type === doorTypes.outer.shortName) {
                return `${paths.drzwiZewnetrzne}/baranski-premium`;
            }
            if (type === doorTypes.technical.shortName) {
                return `${paths.drzwiKlatkoweITechniczne}/baranski-premium`;
            }
            return `${paths.drzwiWewnetrzne}/baranski-premium`;
        }
        if (buttonLink === '/produkty') {
            if (type === doorTypes.outer.shortName) {
                return paths.drzwiZewnetrzne;
            }
            if (type === doorTypes.technical.shortName) {
                return paths.drzwiKlatkoweITechniczne;
            }
            return paths.drzwiWewnetrzne;
        }
        return buttonLink;
    };

    return (
        <CarouselView
            { ...settings }
            headline={ headline }
            buttonText={ buttonText }
            buttonLink={ generateLink() }
            name={ name }
            modifier={ modifier }
            items={
                (type)
                    ? (filterItems())
                    : (items)
            }
            types={ types }
            type={ type }
            loopBreakpoint={ loopBreakpoint }
            loop
            setType={ setType }
        >
            { children }
        </CarouselView>
    );
};

Carousel.propTypes = {
    headline: PropTypes.string.isRequired,
    buttonText: PropTypes.string,
    buttonLink: PropTypes.string,
    name: PropTypes.string.isRequired,
    modifier: PropTypes.string.isRequired,
    items: PropTypes.instanceOf(Array).isRequired,
    types: PropTypes.instanceOf(Array),
    children: PropTypes.node.isRequired,
    loopBreakpoint: PropTypes.number.isRequired
};

Carousel.defaultProps = {
    buttonText: undefined,
    buttonLink: undefined,
    types: undefined
};