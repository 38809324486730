import React from 'react';

import { HeadlineView } from '../../headline/component/view';
import InstagramFeed from '../../../../pages/instagramFeed';

import './view.scss';

export const ImplementationsView = () => (
    <section className="implementations">
        <HeadlineView text="Nasze realizacje" />
        <InstagramFeed refId="1417430e0fe7b7ff4729ea1ae51eb5f8bfc09eb2" />
    </section>
);
