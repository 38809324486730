import React, { useEffect } from 'react';
import classnames from 'classnames';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import PropTypes from 'prop-types';

import { HeadlineView } from '../../../../utilities/components/headline/component/view';
import { ButtonsView } from '../../../../utilities/components/buttons/component/view';
import { ButtonView } from '../../../../utilities/components/button/component/view';

import './view.scss';

SwiperCore.use([Navigation]);

export const CarouselView = ({
    headline,
    buttonText,
    buttonLink,
    name,
    modifier,
    items,
    types,
    type,
    children,
    loopBreakpoint,
    setType,
    ...settings
}) => {
    // sets the first filter from the list
    useEffect(() => {
        if (types?.length > 0) setType(types[0]);
    }, [types]);

    return (
        <section className="carousel">
            <HeadlineView text={ headline } />
            {
                types
                && (
                    <Swiper
                        className="carousel__types"
                        slidesPerView="auto"
                        centerInsufficientSlides
                    >
                        {
                            types.map((currentValue, index) => (
                                <SwiperSlide className="types__type" key={ index }>
                                    <button
                                        className={
                                            classnames('type__button', {
                                                'type__button--active': (type === currentValue)
                                            })
                                        }
                                        type="button"
                                        onClick={ () => {
                                            setType(
                                                (type === currentValue)
                                                    ? ('')
                                                    : (currentValue)
                                            );
                                        } }
                                    >
                                        { currentValue }
                                    </button>
                                </SwiperSlide>
                            ))
                        }
                    </Swiper>
                )
            }
            <Swiper
                { ...settings }
                className="carousel__container"
                navigation={{
                    nextEl: '.buttons__button--next',
                    prevEl: '.buttons__button--previous'
                }}
                loop={ items.length > loopBreakpoint }
                loopedSlides={ items.length }
                centerInsufficientSlides
                key={ `index-carousel-${type}` }
            >
                {
                    items?.map((currentValue, index) => (
                        <SwiperSlide className={ modifier } key={ `index-carousel-${type}${index}` }>
                            { React.cloneElement(children, { ...currentValue }) }
                        </SwiperSlide>
                    ))
                }
                <ButtonsView name={ name } />
            </Swiper>
            {
                buttonText && <ButtonView text={ buttonText } link={ buttonLink } />
            }
        </section>
    );
};

CarouselView.propTypes = {
    headline: PropTypes.string.isRequired,
    buttonText: PropTypes.string,
    buttonLink: PropTypes.string,
    name: PropTypes.string.isRequired,
    modifier: PropTypes.string.isRequired,
    items: PropTypes.instanceOf(Array).isRequired,
    types: PropTypes.instanceOf(Array),
    type: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    loopBreakpoint: PropTypes.number.isRequired,
    setType: PropTypes.func.isRequired
};

CarouselView.defaultProps = {
    buttonText: undefined,
    buttonLink: undefined,
    types: undefined
};